
import Box  from '@mui/material/Box';
import dynamic from "next/dynamic";
import useWindowSize from '../../../_hooks/useWindowSize';
import { count } from '../../../config/helpers';
import NewspaperIcon from '@mui/icons-material/Newspaper';

const Slider = dynamic(() => import("react-slick"));
const HomeArticleContainer = dynamic(() => import("./HomeArticleContainer"));
const PageTitle = dynamic(() => import("@components/Components/Reusables/PageTitle"));
const NextArrow = dynamic(() => import("@components/Components/Reusables/Slick/NextArrow"));
const PrevArrow = dynamic(() => import("@components/Components/Reusables/Slick/PrevArrow"));

  

const HomeArticles = ({home_article_count, articles}) => {
  const {isMobile} = useWindowSize();
  const settings = {
    infinite: home_article_count > count(articles) ? false : true,
    dots: false,
	arrows: true,
    slidesToShow:  count(articles) >= 5 ? 5 : count(articles),
	slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: count(articles) >= 4 ? 4 : count(articles),
		  slidesToScroll: count(articles) >= 4 ? 4 : count(articles),
        }
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: count(articles) >= 3 ? 3 : count(articles),
		  slidesToScroll: count(articles) >= 3 ? 3 : count(articles),
          arrows: false,
		  dots: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
		  dots: true,
          slidesToShow: count(articles) >= 2 ? 2 : count(articles),
		  slidesToScroll: count(articles) >= 2 ? 2 : count(articles),
        }
      },
    ],
    draggable: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    swipeToSlide: true,
    focusOnSelect: true,
    arrows:true,
    speed: 500,
    centerMode: false,
  };

	return (
		<Box 
			style={{width: '100%'}}
			sx={{
				':hover': {
					'& .slick-arrow-prev': {
						opacity: 1,
						left: '18px',
					},
					'& .slick-arrow-next': {
						opacity: 1,
						right: '18px',
					}
				},
				
				'.slick-slide > div:first-of-type': {
					padding: {
						xs: '0px 4px 0px 4px',
						lg: '0px 4px 0px 4px',
					}
				},
				'& .slick-arrow-prev': {
					left: '8px',
					opacity: 0
				},
				'& .slick-arrow-next': {
					right: '8px',
					opacity: 0
				}
			}}
		>
	
			<PageTitle sx={{color: '#616467'}} component="h3" className="text-center uppercase">Latest Blog Posts</PageTitle>

			<Slider  {...settings}>
				{articles.map((article, index) => (
					<Box  key={index}  className='w-100'>
						<HomeArticleContainer article ={article} index={index}/>
					</Box>
				)
			)}
			</Slider>
		</Box>
	)
}

export default HomeArticles